import {
	Flex,
	Text,
	Image,
	ResponsiveValue,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import {
	staticModeState,
	showBlockRippleState,
	editingBlockHeightState,
	circlesBreakpointState,
	confModeState,
	confUpperMessageState,
	supplyIntroCompleteState,
	confBgImgState,
	confUpperImgState,
} from '../../../state'

import { DataLastBlockReward } from './data-components'
import { useAppSelector } from '../../../hooks'
import { getBreakpointValue } from '../../../utils'
import { 
	blurInFastAnimation,
	BITCOIN_ORANGE,
	blurInAnimation,
} from '../../../constants'
import { confModeFontSize } from './data-components/dataComponents.constants'

export const Upper = () => {
	const confMode = useRecoilValue(confModeState)
	const confBgImg = useRecoilValue(confBgImgState)
	const confUpperMessage = useRecoilValue(confUpperMessageState)
	const confUpperImg = useRecoilValue(confUpperImgState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const staticMode = useRecoilValue(staticModeState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const editingBlockHeight = useRecoilValue(editingBlockHeightState)
	const supplyIntroComplete = useRecoilValue(supplyIntroCompleteState)

	const { showData } = useAppSelector(({ settings }) => settings)

	const isConfBackground = confMode && confBgImg
	const responsiveDisplay = getBreakpointValue({ base: 'none', sm: 'flex' }, circlesBreakpoint) as ResponsiveValue<'none' | 'flex'>
	const responsiveAlign = confMode
		? 'center'
		: getBreakpointValue({ base: 'center', lg: 'flex-end' }, circlesBreakpoint) as ResponsiveValue<'center' | 'flex-end'>
	const responsivePosition = getBreakpointValue({ base: 'relative', sm: 'absolute' }, circlesBreakpoint) as ResponsiveValue<'relative' | 'absolute'>
	const responsiveTop = getBreakpointValue({
		base: undefined,
		sm: '30.85%',
		md: '30.75%',
		lg: '28.6%',
		xl: '28.85%',
		xxxl: '29.25%',
		jumbo: '30.5%',
	}, circlesBreakpoint)
	const rTop = isConfBackground ? '30.5%' : responsiveTop
	const responsiveSides = getBreakpointValue({
		base: undefined,
		sm: '25.5%',
		lg: '23.5%',
		xxxl: '22.5%',
	}, circlesBreakpoint)
	const responsiveDirection = getBreakpointValue({ base: 'column', xxs: 'row' }, circlesBreakpoint) as ResponsiveValue<'column' | 'row'>
	const responsiveP = confMode
		? '12px'
		: getBreakpointValue({ base: '0', lg: '18px 12px 12px' }, circlesBreakpoint)
	const responsiveMb = getBreakpointValue({ base: 7, sm: undefined }, circlesBreakpoint)
	const confModeMessageFontSize = getBreakpointValue(confModeFontSize, circlesBreakpoint)

	const shouldBlur = staticMode || showBlockRipple || editingBlockHeight
	const opacity = shouldBlur ? 0.21 : 1
	const filter = shouldBlur ? 'blur(4px)' : 'blur(0)'

	if (!showData) return null

	return (
		<>
			{supplyIntroComplete && !editingBlockHeight && (
				<Flex
					className="tc-upper-section"
					display={responsiveDisplay}
					direction={responsiveDirection}
					gap={{ base: 4, xxs: 0 }}
					align={responsiveAlign}
					justify="center"
					pos={responsivePosition}
					zIndex={2008}
					top={rTop}
					left={responsiveSides}
					right={responsiveSides}
					p={responsiveP}
					mb={responsiveMb}
					animation={blurInFastAnimation}
					transition="all 0.45s ease"
				>
					{!confMode && (
						<DataLastBlockReward />
					)}

					{confMode && confUpperImg && (
						<Flex
							zIndex={99}
							w="80%"
							justify="center"
							opacity={opacity}
							filter={filter}
							animation={blurInAnimation}
							transition="all 0.45s ease"
						>
							
							<Image
								src={confUpperImg}
								boxSize="100%"
							/>
						</Flex>
					)}
		
					{confMode && confUpperMessage && (
						<Text
							w="96%"
							textAlign="center"
							fontSize={confModeMessageFontSize}
							lineHeight="none"
							color={BITCOIN_ORANGE}
							filter={filter}
							opacity={opacity}
						>
							{confUpperMessage}
						</Text>
					)}
				</Flex>
			)}
		</>
	)
}
