import {
	useLayoutEffect,
	useState,
	useRef,
} from 'react'
import format from 'date-fns/format'
import add from 'date-fns/add'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import {
	Flex,
	Box,
	Text,
	Modal,
	ModalContent,
	ModalFooter,
	ModalBody,
	Button,
	useColorModeValue,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { useRecoilValue } from 'recoil'
import { gsap } from 'gsap'

import {
	blockState,
	futureBlockState,
	futureBlockDateState,
	futureBlockTimeState,
} from '../../../../state'
import { dateLocales } from '../../../../lang/dateFnsLocales'
import { FutureSearchSlider } from './FutureSearchModal.components'
import {
	getDateFormat,
	getTimeFormat,
	getAmPm,
	updateTime,
	getAvgBlockTime,
	getBitcoinEpoch,
	getMinerSubsidy,
	calculateDecimalPlaces,
} from '../../../../utils'
import { useAppSelector } from '../../../../hooks'
import { Label, BitcoinSymbolEntity, ModalOverlay } from '../../../shared'
import {
	TEN_MINUTES_MS,
	BITCOIN_ORANGE,
	romanNumerals,
} from '../../../../constants'

interface FutureSearchModalProps {
	isOpen: boolean
	onClose: () => void
	height: number
}

export const FutureSearchModal = ({
	isOpen,
	onClose,
	height,
}: FutureSearchModalProps) => {
	const { dateFormat, timeFormat, userLocale } = useAppSelector(({ settings }) => settings)

	const block = useRecoilValue(blockState)
	const futureBlock = useRecoilValue(futureBlockState)
	const futureDate = useRecoilValue(futureBlockDateState)
	const futureTime = useRecoilValue(futureBlockTimeState)

	const [blockTimeMs, setBlockTimeMs] = useState(TEN_MINUTES_MS)

	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')

	const modalRef = useRef<HTMLDivElement>(null)
	const wizardBgRef = useRef<HTMLDivElement>(null)
	const titleRef = useRef<HTMLDivElement>(null)
	const futureBlockRef = useRef<HTMLDivElement>(null)
	const futureBlockDetailsRef = useRef<HTMLDivElement>(null)

	const isBlockSearch = (futureDate === null || futureTime === null) && futureBlock !== null
	const isDateSearch = futureBlock === null && (futureDate !== null || futureTime !== null)

	const rightNow = new Date()
	const avgBlockTime = (blockTimeMs / 60000)
	const blockDiff = isBlockSearch ? (futureBlock - height) * avgBlockTime : 0

	// isBlockSearch
	const calculatedFutureDate = futureBlock && futureBlock > 0
		? [rightNow]
			.map((date) => add(date, { minutes: blockDiff }))
			.map((date) => format(date, getDateFormat(dateFormat), { locale: dateLocales[userLocale] }))
		: ''
	const calculatedFutureTime = futureBlock && futureBlock > 0
		? [rightNow]
			.map((date) => add(date, { minutes: blockDiff }))
			.map((date) => format(date, getTimeFormat(timeFormat)))
		: ''
	const futureTimeAmPm = futureBlock && futureBlock > 0
		? [rightNow]
			.map((date) => add(date, { minutes: blockDiff }))
			.map((date) => getAmPm(date, timeFormat))
		: ''

	// isDateSearch
	const futureDateTime = futureDate && futureTime
		? new Date(updateTime(futureDate, futureTime))
		: rightNow
	const minutesDiffBlocks = futureDate && futureTime
		? (differenceInMinutes(futureDateTime, rightNow) / 10).toFixed(0)
		: 0
	const calculateFutureBlock = () => {
		if (futureDate && futureTime) {
			return Number((differenceInMilliseconds(futureDateTime, rightNow) / blockTimeMs).toFixed(0))
		}
		return null
	}
	const calculatedFutureBlockDiff = calculateFutureBlock()
	const calculatedFutureBlock = Number(minutesDiffBlocks) > 0 && calculatedFutureBlockDiff
		? height + calculatedFutureBlockDiff
		: null

	const avgBlockTimeSeconds = getAvgBlockTime(blockTimeMs)
	const futureBlockEpoch = isBlockSearch && futureBlock
		? getBitcoinEpoch(futureBlock)
		: isDateSearch && calculatedFutureBlock
			? getBitcoinEpoch(calculatedFutureBlock)
			: 1
	const futureBlockRomanNumeral = romanNumerals[futureBlockEpoch - 1]
	const futureSubsidy = getMinerSubsidy(futureBlockEpoch)
	const futureSubsidyFormatted = futureSubsidy ? futureSubsidy.toFixed(calculateDecimalPlaces(futureSubsidy)) : '0'
	const subsidyColor = futureSubsidyFormatted === '0' ? 'red' : 'white'
	let blocksToGo
	if (calculatedFutureBlock) {
		blocksToGo = calculatedFutureBlock - block.height
	}
	if (futureBlock) {
		blocksToGo = futureBlock - block.height
	}

	const handleBlockTimeChange = (value: number) => {
		setBlockTimeMs(value)
	}

	const handleClose = () => {
		setBlockTimeMs(TEN_MINUTES_MS)
		onClose()
	}

	useLayoutEffect(() => {
		setTimeout(() => {
			const ctx = gsap.context(() => {
				if (modalRef.current && isOpen) {
					const tl = gsap.timeline()
					tl.to(wizardBgRef.current, {
						scale: 1.15,
						duration: 1,
						ease: 'easeInOut',
					})
					tl.to(wizardBgRef.current, {
						opacity: 0.4,
						scale: 2,
						filter: 'grayscale(1) blur(5px)',
						y: 90,
						duration: 2.1,
						ease: 'easeInOut',
						delay: 0.5,
					}, '>')
					tl.to(titleRef.current, {
						opacity: 1,
						duration: 2.1,
						delay: 1.25,
					}, '<')
					tl.to(futureBlockRef.current, {
						opacity: 1,
						scale: 1,
						filter: 'blur(0px)',
						duration: 2,
					}, '<')
					tl.to(futureBlockDetailsRef.current, {
						opacity: 1,
						filter: 'blur(0px)',
						duration: 2.5,
						delay: 1,
					}, '<')
				}
			}, modalRef)

			return () => ctx.revert()
		}, 100)
	}, [isOpen])

	return (
		<Modal
			key="future"
			isOpen={isOpen}
			onClose={() => {
				setBlockTimeMs(TEN_MINUTES_MS)
				onClose()
			}}
			isCentered
			motionPreset="slideInBottom"
			size="sm"
		>
			<ModalOverlay />

			<ModalContent
				ref={modalRef}
				bg={bg}
				pos="relative"
				borderWidth={1}
				borderColor={color}
				color={color}
				borderRadius="lg"
				dir="direction"
			>
				{isOpen && (
					<>
						<Box
							pos="absolute"
							zIndex={0}
							top={0}
							left={0}
							right={0}
							bottom={0}
							overflow="hidden"
						>
							<Box
								ref={wizardBgRef}
								pos="absolute"
								top={0}
								left={0}
								right={0}
								bottom={0}
								bgImage="/images/wizard-orb.jpeg"
								bgPos="top"
								bgSize="cover"
							/>
						</Box>

						<ModalBody
							pos="relative"
							zIndex={1}
							mt={3}
						>
							<Flex
								direction="column"
								align="center"
								gap={4}
							>
								<Flex
									ref={titleRef}
									direction="column"
									align="center"
									pt={2}
									mb={2}
									opacity={0}
								>
									<Text
										textTransform="uppercase"
										fontWeight="bold"
										lineHeight="shorter"
									>
										<FormattedMessage id="future.modal_title" />
									</Text>

									<Text
										fontSize="xs"
										fontWeight="semibold"
										textTransform="uppercase"
										color={colorAlt}
									>
										{isBlockSearch
											? <FormattedMessage id="search.modal_button_label.by_block" />
											: <FormattedMessage id="search.modal_button_label.by_date" />
										}
									</Text>
								</Flex>

								<Flex
									direction="column"
									align="center"
									w="86%"
									gap={3}
								>
									<Flex
										ref={futureBlockRef}
										direction="column"
										align="center"
										opacity={0}
										filter="blur(10px)"
										mb={2}
									>
										<Label color={colorAlt} fontWeight="bold">
											<FormattedMessage id="future.block" />
										</Label>
										<Text
											fontSize="38px"
											lineHeight="shorter"
											fontWeight="semibold"
										>
											{isBlockSearch
												? futureBlock
												: calculatedFutureBlock
											}
										</Text>
										<Label
											fontSize="20px"
											lineHeight="shorter"
											fontWeight="semibold"
											color={colorAlt}
											display="flex"
											alignItems="center"
											gap={2}
											mt={-1}
										>
											{blocksToGo} <span style={{ fontSize: '14px' }}><FormattedMessage id="data.next_halving.blocks.lower_case" /> <FormattedMessage id="data.next_halving.to_go.lower_case" /></span>
										</Label>
									</Flex>

									<Flex
										ref={futureBlockDetailsRef}
										w="100%"
										direction="column"
										align="center"
										gap={3}
										opacity={0}
										filter="blur(10px)"
									>
										<Flex w="100%">
											<Flex
												direction="column"
												align="center"
												flex="1 0 50%"
											>
												<Label color={colorAlt} fontWeight="bold">
													<FormattedMessage id="nw_corner.epoch" />
												</Label>
												<Text
													textStyle="roman"
													fontSize="30px"
													lineHeight="none"
													letterSpacing="tighter"
												>
													{futureBlockRomanNumeral}
												</Text>
											</Flex>

											<Flex
												direction="column"
												align="center"
												flex="1 0 50%"
												mb={2}
											>
												<Label color={colorAlt} fontWeight="bold">
													<FormattedMessage id="nw_corner.subsidy" />
												</Label>
												<Text
													fontSize="20px"
													lineHeight="shorter"
													fontWeight="semibold"
													color={subsidyColor}
												>
													<BitcoinSymbolEntity />
													{futureSubsidyFormatted}
												</Text>
											</Flex>
										</Flex>

										<Flex w="100%">
											<Flex
												flex="1 0 50%"
												direction="column"
												align="center"
												mb={2}
											>
												<Label color={colorAlt} fontWeight="bold">
													<FormattedMessage id="future.date" />
												</Label>
												<Text
													fontSize="20px"
													lineHeight="shorter"
													fontWeight="semibold"
													textTransform="uppercase"
													textAlign="center"
												>
													{isDateSearch
														? format(futureDateTime, getDateFormat(dateFormat), { locale: dateLocales[userLocale] })
														: calculatedFutureDate
													}
												</Text>
											</Flex>

											<Flex
												flex="1 0 50%"
												direction="column"
												align="center"
												mb={2}
											>
												<Label color={colorAlt} fontWeight="bold">
													<FormattedMessage id="future.time" />
												</Label>
												<Text
													fontSize="20px"
													lineHeight="shorter"
													fontWeight="semibold"
													textAlign="center"
												>
													{isDateSearch
														? format(futureDateTime, getTimeFormat(timeFormat))
														: calculatedFutureTime
													} {isDateSearch
														? getAmPm(futureDateTime, timeFormat)
														: futureTimeAmPm
													}
												</Text>
											</Flex>
										</Flex>

										<Flex direction="column" align="center" pt={2}>
											<Label color={colorAlt} fontWeight="bold">
												<FormattedMessage id="future.average_block_time" />
											</Label>
											<Text
												fontSize="20px"
												lineHeight="shorter"
												fontWeight="semibold"
												mb={-1}
											>
												{avgBlockTimeSeconds}
											</Text>
										</Flex>

										<Flex direction="column" align="center" w="100%" pb={4}>
											<FutureSearchSlider blockTimeMs={blockTimeMs} onChange={handleBlockTimeChange} />
										</Flex>
									</Flex>
								</Flex>
							</Flex>
						</ModalBody>

						<ModalFooter gap={2} color={color}>
							<Button
								bg={bg}
								onClick={handleClose}
								variant="outline"
								colorScheme="black"
								fontSize="sm"
								borderWidth={2}
								textTransform="uppercase"
								_hover={{
									borderColor: BITCOIN_ORANGE,
									color: BITCOIN_ORANGE,
								}}
							>
								<FormattedMessage id="shared.close_button_label" />
							</Button>
						</ModalFooter>
					</>
				)}
			</ModalContent>
		</Modal>
	)
}
